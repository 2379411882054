.btn {
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: 16px;
  padding: 16px 64px;
  text-transform: uppercase;
}

.btn-primary {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  cursor: pointer;
  margin: auto;
}

.btn-secondary {
  background-color: var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  margin: auto;
}

.btn-icon {
  background-color: var(--color-grey-300);
  border: none;
  cursor: pointer;
  outline: 0;
  padding: var(--padding-m);

  &:hover {
    background-color: var(--color-white);
  }
}

.btn-letter {
  background-color: var(--color-primary-100);
  color: var(--color-primary-300);
  border: none;
  cursor: pointer;
  outline: 0;
  padding: var(--padding-m);

  &:hover {
    background-color: var(--color-white);
  }
}

.btn-icon-letter {
  background-color: var(--color-white);
  border: none;
  cursor: pointer;
  outline: 0;
  padding: 0;
  border-radius: 20px;

  &:hover {
    background-color: var(--color-grey-300);
  }
}

.btn-no-style {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: 0;
  padding: 0;
}

.btn-disabled {
  background-color: var(--color-grey-500);
  color: var(--color-grey-300);
  opacity: 0.8;
}
