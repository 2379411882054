.popover-container {
  padding: var(--padding-l);
  padding-right: var(--padding-xl);
  position: fixed;
  top: 20px;
  right: 0;
  border-radius: 10px 0 0 10px;
}

.popover-success {
  background-color: var(--color-success-300);
  color: var(--color-success);
}

.popover-error {
  background-color: var(--color-error-300);
  color: var(--color-error-500);
}

.close-button {
  font-size: var(--font-s);
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button-success {
  color: var(--color-success);
}

.close-button-error {
  color: var(--color-error-500);
}
