.action-modal {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 40;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.8);

  .action-modal-content {
    background-color: var(--color-grey-300);
    border-radius: 5px;
    max-width: 600px;
    max-height: 100vh;
    overflow: auto;
  }
}
