//Colors
.error-text {
  color: var(--color-error-500);
}

.success-text {
  color: var(--color-success-300);
}

.white-text {
  color: var(--color-white);
}

.grey-text {
  color: var(--color-grey-500);
}

.dark-grey-text {
  color: var(--color-grey-700);
}

.primary-text {
  color: var(--color-primary);
}

.dark-primary-text {
  color: var(--color-primary-300);
}

.secondary-text {
  color: var(--color-secondary);
}

//transform

.uppercase {
  text-transform: uppercase;
}

//font style

.italic {
  font-style: italic;
}

.normal-style {
  font-style: normal;
}

.underline {
  text-decoration: underline;
}

.no-decoration {
  text-decoration: none;
}

//letter spacing

.ls-5 {
  letter-spacing: 5px;
}

//weight
.fw-light {
  font-weight: 100;
}

.fw-bold {
  font-weight: bold;
}

//Sizes
$f-sizes: ( s : var(--fs-s), m: var(--fs-m), l: var(--fs-l), xl: var(--fs-xl));

@each $name, $size in $f-sizes {
  .font-#{$name} {
    font-size: $size;
  }
}

//overflow ellipsis
.t-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
