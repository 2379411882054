.input-yellow-label {
  color: var(--color-primary);
  font-size: var(--fs-m);
  top: -20px;
  position: relative;

  .bar::before {
    background: var(--color-primary);
    bottom: 3px;
    content: '';
    height: 2px;
    position: absolute;
    -webkit-transition: 0.2s ease width;
    transition: 0.2s ease width;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .error-bar::before {
    width: calc(100% - 10px);
    background: var(--color-error-500);
  }

  .input:focus ~ .bar::before {
    width: calc(100% - 10px);
  }
}

.input {
  background-color: var(--color-white) !important;
  border: 0;
  border-bottom: 1px solid var(--color-white);
  display: block;
  padding: 10px;
  margin: var(--margin-xs) 0;
  width: calc(100% - 20px);
  border-radius: 5px;
}

.eye-icon {
  right: 7px;
}

input {
  &:focus {
    outline: 0;
  }
}

//File input

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: var(--color-primary-100);
}

body {
  padding: 20px;
}

.file-selected {
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
}
