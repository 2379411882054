.full-screen {
  width: 100vw;
  min-height: 100vh;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.w30-percent {
  width: 30%;
}

.w50-percent {
  width: 50%;
}

.wmin-200 {
  min-width: 200px;
}

//flex
.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content:flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-no-wrap {
  flex-wrap: nowrap;
}

.f-row {
  flex-direction: row;
}

.f-column {
  flex-direction: column;
}

//position

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

//text

.text-center {
  text-align: center;
}
