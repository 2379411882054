.header-container {
  width: 100vw;
  background-color: var(--color-grey-300);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px 0;
}

.logout {
  height: 23px;
  width: 23px;

  g {
    [fill] {
      fill: var(--color-primary-300);
    }
  }
}

