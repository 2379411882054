.footer-container {
  background-color: var(--color-grey-300);
  position: fixed;
  bottom: 0;
  width: calc(100vw - 2 * var(--padding-m));
  padding: var(--padding-m);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-link {
  &:hover {
    text-decoration: underline;
  }
}
