:root {
  /* COLORS */
  --color-primary: #fecc00;
  --color-primary-300: #bb9b03;
  --color-primary-100: rgba(187, 155, 0, 0.1);
  --color-secondary: #4b4b4d;
  --color-error-300: #eeafb8;
  --color-error-500: #d4364d;
  --color-error-700: #7f202e;
  --color-success: #145824;
  --color-success-300: #D4EDD9;
  --color-grey-300: #f5f5f9;
  --color-grey-500: #a4a4a6;
  --color-grey-700: #5d5d5d;
  --color-white: #fff;
  --color-black: #232326;

  /* Primary font */
  --font-primary-regular: sans-serif;
  --font-primary-medium: sans-serif;
  --font-primary-bold: sans-serif;

  /* Secondary font */
  --font-secondary-regular: mono;
  --font-secondary-medium: mono;
  --font-secondary-semibold: mono;

  /* Font Sizes */
  --fs-s: 0.5rem;
  --fs-m: 1rem;
  --fs-l: 1.25rem;
  --fs-xl: 1.75rem;

  /* Padding */
  --padding-xs: 0.25rem;
  --padding-s: 0.5rem;
  --padding-m: 1rem;
  --padding-l: 1.5rem;
  --padding-xl: 2rem;

  /* Margin */
  --margin-xs: 0.25rem;
  --margin-s: 0.5rem;
  --margin-m: 1rem;
  --margin-l: 1.5rem;
  --margin-xl: 2rem;
  --margin-xxl: 3rem;

  --menu-width: 120px;
}

body {
  font-size: 16px; /* set the rem basis so no variable here */
  font-family: var(--font-primary-regular);
  color: var(--color-black);
  padding: 0;
  margin: 0;
}
