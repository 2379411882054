.grey-background {
  background-color: var(--color-grey-300);
}

.tooltip {
  position: relative;
  .tooltip-text {
    visibility: hidden;
    background-color: var(--color-grey-500);
    color: var(--color-white);
    padding: var(--padding-s);
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: -30px;
    white-space: nowrap;
  }

  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
}

.pointer {
  cursor: pointer;
}
