$margins: ( xs : var(--margin-xs), s: var(--margin-s), m: var(--margin-m), l: var(--margin-l), xl: var(--margin-xl), xxl: var(--margin-xxl));

@each $name, $margin in $margins {
  .#{$name}-margin {
    margin: $margin;
  }
  .#{$name}-margin-left {
    margin-left: $margin;
  }
  .#{$name}-margin-right {
    margin-right: $margin;
  }
  .#{$name}-margin-top {
    margin-top: $margin;
  }
  .#{$name}-margin-bottom {
    margin-bottom: $margin;
  }
  .#{$name}-margin-horizontal {
    margin-left: $margin;
    margin-right: $margin;
  }
  .#{$name}-margin-vertical {
    margin-top: $margin;
    margin-bottom: $margin;
  }
}

.margin-0 {
  margin: 0;
}

.margin-vertical-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.avoid-left-menu {
  margin-left: var(--menu-width);
}

$paddings: ( xs : var(--padding-xs), s: var(--padding-s), m: var(--padding-m), l: var(--padding-l), xl: var(--padding-xl));

@each $name, $padding in $paddings {
  .#{$name}-padding {
    padding: $padding;
  }
  .#{$name}-padding-left {
    padding-left: $padding;
  }
  .#{$name}-padding-right {
    padding-right: $padding;
  }
  .#{$name}-padding-top {
    padding-top: $padding;
  }
  .#{$name}-padding-bottom {
    padding-bottom: $padding;
  }
  .#{$name}-padding-horizontal {
    padding-left: $padding;
    padding-right: $padding;
  }
  .#{$name}-padding-vertical {
    padding-top: $padding;
    padding-bottom: $padding;
  }
}

.padding-0 {
  padding: 0 !important;
}
