.profile-box-container {
  background-color: var(--color-primary-100);
  padding: var(--padding-m);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 200px;
  height: 150px;
}

.profile-box-highlight {
  background-color: var(--color-primary);
}
