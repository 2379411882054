.home-point-circle {
  top: 0;
  right: 0;
  background: var(--color-primary);
  border-radius: 100px 0 0 100px;
  padding: var(--padding-l) var(--padding-xl);
}

.menu-tiles-container {
  margin: 0 120px;
  max-width: 600px;
}

@media (max-width: 1030px) {
  .home-point-circle {
    width: calc(100% - 2 * var(--padding-xl) - var(--menu-width));
    position: relative;
    border-radius: 0;
    margin-left: var(--menu-width);
  }
}

@media (max-width: 550px) {
  .home-point-circle {
    width: calc(100% - 2 * var(--padding-xl));
    margin-left: 0;
  }
}
