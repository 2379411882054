.left-menu-container {
  background-color: var(--color-grey-300);
  top: 99px;
  border-radius: 0 0 50px 0;
  height: calc(100vh - 160px - 2 * var(--padding-xl));
  width: var(--menu-width);
}

@media (max-width: 550px) {
  .left-menu-container {
    display: none;
  }
}

.menu-hover:hover {
  .menu-icon {
    opacity: 0.5;
  }
}
